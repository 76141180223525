import { createRouter, createWebHashHistory } from "vue-router"

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/components/homePage')
    },
    // {
    //     path: '/foot',
    //     name: 'datafoot',
    //     component: () => import('@/components/layout/layout/datafoot')
    // },
    {
        path: '/information',
        name: 'information',
        component: () => import('@/components/information')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/components/about')
    },
    {
        path: '/resources',
        name: 'resources',
        component: () => import('@/components/resources')
    },
    {
        path: '/market',
        name: 'market',
        component: () => import('@/components/market')
    },
    {
        path: '/ProductCenter',
        name: 'ProductCenter',
        component: () => import('@/components/ProductCenter')
    },
    {
        path: '/productList',
        name: 'productList',
        component: () => import('@/components/productList')
    },
    {
        path: '/productDetails',
        name: 'productDetails',
        component: () => import('@/components/productDetails')
    },
]
export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

export default router