import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import VueLazyload from 'vue-lazyload'
// 导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入响应式计算
import 'amfe-flexible'
// 引入ant_design
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
// 引入less-loader
import less from 'less'
// 引入animate
import animate from "animate.css";
// 引入swper
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

 
const app = createApp(App)
 
app.use(ElementPlus)
app.use(VueAwesomeSwiper);
app.use(less)
app.use(animate)
app.use(router)
app.use(Antd);
app.mount('#app')

app.use(VueLazyload)
