<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <homePage msg="Welcome to Your Vue.js App"/> -->
  <router-view></router-view>
</template>

<script>
// import homePage from './components/homePage.vue'
// import index from './components/index.vue'

export default {
  name: 'App',
  components: {
    // homePage
    // index
  },

  created() {
    document.body.removeChild(document.getElementById('Loading'))   // 加载页面完后移除加载动画
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
